<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :model="params" :size="theme.size" inline>
				<el-form-item>
					<el-input v-model="params.keyword" placeholder="采购/商家/备注说明" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.suppliers" placeholder="请选择供应商" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="supplier in suppliers" :key="supplier.id" :label="supplier.name" :value="supplier.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.warehouses" placeholder="请选择仓库" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="warehouse in warehouses" :key="warehouse.id" :label="warehouse.name" :value="warehouse.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="请选择状态" filterable multiple collapse-tags>
						<el-option v-for="(status, s) in statuses" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getInventories(params)">查询</el-button>
					<el-button type="primary" :disabled="!$utils.create($api.URI_MATERIALS_PURCHASES)" @click="purchase = {}; create_dialog = true;">添加采购</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="inventories" :size="theme.size" @sort-change="sortChange">
				<el-table-column label="创建人" prop="employee.name" min-width="100"></el-table-column>
				<el-table-column label="供应商家" prop="supplier.name" min-width="100"></el-table-column>
				<el-table-column label="流水单号" min-width="230">
					<template slot-scope="scope">
						<p>采购：{{scope.row.no}}</p>
						<p v-if="scope.row.out_no">商家：{{scope.row.out_no}}</p>
					</template>
				</el-table-column>
				<el-table-column label="物料清单" min-width="80">
					<el-link slot-scope="scope" type="primary" @click="purchase = { ...scope.row }; getSheets({material_purchase_id: scope.row.id, perPage: 100}); sheets_drawer = true;">{{scope.row.purchase_materials_count}} 款</el-link>
				</el-table-column>
				<el-table-column label="状态" min-width="80">
					<template slot-scope="scope">
						<el-link type="primary" @click="onStatus(scope.row)">{{statuses[scope.row.status]}}</el-link>
						<p v-if="scope.row.warehouse">{{scope.row.warehouse.name}}</p>
					</template>
				</el-table-column>
				<el-table-column label="备注说明" prop="remarks" min-width="160"></el-table-column>
				<template v-if="theme.width >= 1420">
					<el-table-column label="更新时间" min-width="160" prop="updated_at"></el-table-column>
					<el-table-column label="创建时间" min-width="160" prop="created_at"></el-table-column>
				</template>
				<el-table-column label="操作" width="60">
					<template slot-scope="scope">
						<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_MATERIALS_PURCHASES)" v-if="!scope.row.deleted_at">删除</el-button>
						<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore($api.URI_MATERIALS_PURCHASES)" v-else>恢复</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getInventories({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getInventories({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<!-- 编辑添加 -->
		<el-dialog width="420px" :title="purchase.id ? '编辑采购' : '添加采购'" :visible.sync="create_dialog" @closed="purchase = {}" append-to-body destroy-on-close>
			<el-form ref="create_form" label-width="80px" :model="purchase" :size="theme.size" status-icon v-if="purchase.id">
				<el-form-item label="采购单号" prop="out_no" :rules="[{ required: true, message: '请输入商家提供单号', trigger: 'blur' }]">
					<el-input v-model="purchase.out_no" placeholder="请输入商家提供单号" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
				</el-form-item>
			</el-form>
			<el-form ref="create_form" label-width="80px" :model="purchase" :size="theme.size" status-icon v-else>
				<el-form-item label="供应商" prop="supplier_id" :rules="[{ required: true, message: '请选择供应商', trigger: 'blur' }]">
					<el-select v-model="purchase.supplier_id" placeholder="请选择供应商">
						<el-option v-for="supplier in suppliers" :key="supplier.id" :label="supplier.name" :value="supplier.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注" prop="remarks">
					<el-input type="textarea" rows="3" v-model="purchase.remarks" placeholder="用一段话简单描述备注说明。" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 物料清单 -->
		<el-drawer :size="theme.width <= 640 ? '100%' : '640px'" :title="purchase.no + ' 采购物料清单'" :visible.sync="sheets_drawer">
			<div style="padding: 0 10px;">
				<div style="line-height: 1.5; padding: 10px; color: #666; font-size: 14px; background-color: #f9f9f9;">
					<p v-if="purchase.supplier">供应商：{{purchase.supplier.name}}</p>
					<p v-if="purchase.warehouse">已入库：{{purchase.warehouse.name}}</p>
					<p v-if="purchase.remarks">备注说明：{{purchase.remarks}}</p>
				</div>
				<el-table :data="sheets" :size="theme.size">
					<el-table-column label="编码" min-width="140" prop="in_code" sortable="custom"></el-table-column>
					<el-table-column label="名称" min-width="140" prop="name"></el-table-column>
					<el-table-column label="数量" min-width="130" v-if="purchase.status == 1 || purchase.status == 3">
						<el-input-number slot-scope="scope" size="mini" v-model="scope.row.amount" @change="(e) => { submitSheets({id: scope.row.id, amount: e}, () => { getSheets({material_purchase_id: purchase.id,  perPage: 100}); }); }" :min="1" :max="scope.row.max_amount || 0" label="数量"></el-input-number>
					</el-table-column>
					<el-table-column label="数量" min-width="110" prop="amount" v-else></el-table-column>
					<el-table-column label="确认数量" min-width="130" v-if="purchase.status == 5">
						<el-input-number slot-scope="scope" size="mini" v-model="scope.row.confirmed" :min="0" label="数量"></el-input-number>
					</el-table-column>
					<el-table-column label="操作" width="60" v-if="purchase.status == 1 || purchase.status == 3">
						<template slot-scope="scope">
							<el-popconfirm title="确定删除这条记录吗？" @confirm="delSheet(scope.row)">
								<el-button slot="reference" type="text">删除</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
				<el-form ref="create_sheet" style="margin-top: 10px;" :model="sheet" :size="theme.size" v-if="purchase.status == 1 || purchase.status == 3" inline>
					<el-form-item prop="no" :rules="[{ required: true, message: '请输入物料编码', trigger: 'blur' }]">
						<el-input v-model="sheet.no" placeholder="请输入物料编码" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('create_sheet')">添加物料</el-button>
						<el-upload style="display: inline-block; margin: 0 10px" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importSupplies" :action="$api.URI_UPLOADS">
							<el-button type="primary" plain>批量导入</el-button>
						</el-upload>
						<el-button type="text" @click="downloadTemp">下载模板</el-button>
					</el-form-item>
				</el-form>
				<el-form ref="create_form" style="margin-top: 10px;" :model="purchase" :size="theme.size" status-icon v-if="purchase.status == 5" inline>
					<el-form-item label="入库仓库" prop="warehouse_id" :rules="[{ required: true, message: '请选择入库仓库', trigger: 'blur' }]">
						<el-select v-model="purchase.warehouse_id" placeholder="请选择入库仓库">
							<el-option v-for="warehouse in warehouses" :key="warehouse.id" :label="warehouse.name" :value="warehouse.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import XLSX from "xlsx";
	import mainTable from '@/components/main-table';
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';

	export default {
		components: {
			mainTable,
		},
		computed: {
			...mapState(['theme', 'admin_user', 'models']),
			table_temp () {
				const thead = ['编码', '数量'];
				const filter = ['material_no', 'amount'];
				return {
					data: [],
					sheet: '物料明细',
					thead,
					filter
				};
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getInventories(this.params);
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.no+'】物料采购吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS_PURCHASES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getInventories(this.params);
					this.$message.success(msg);
				});
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.no+'】物料采购吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS_PURCHASES, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getInventories(this.params);
					this.$message.success(msg);
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'create_form':
							if (this.purchase.status == 5) {
								this.purchase.materials = this.sheets;
							}
							this.submitInventories(this.purchase, () => {
								this.getInventories(this.params);
								this.sheets_drawer = false;
								this.create_dialog = false;
							});
						break;
						case 'create_sheet':
							this.submitSheets({
								material_purchase_id: this.purchase.id,
								material_no: this.sheet.no,
							}, () => {
								this.getSheets({material_purchase_id: this.purchase.id,  perPage: 100});
								this.sheet = {};
							});
						break;
						default:
							this.$message.error("操作未知表单");
						break;
					}
				})
			},
			async submitInventories (data, onClose) {
				const res = await this.$http.post(this.$api.URI_MATERIALS_PURCHASES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose
				});
			},
			async submitSheets (data, onClose) {
				const res = await this.$http.post(this.$api.URI_MATERIALS_PURCHASES_SHEETS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose
				});
			},
			async getInventories (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_PURCHASES, {params, headers: {loading}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.inventories = result.data;
				this.statuses = result.statuses;
				this.suppliers = result.suppliers;
				this.warehouses = result.warehouses;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async getSheets (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_PURCHASES_SHEETS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.sheets = result.data.map((s) => {
					s.confirmed = s.amount;
					return s;
				});
			},
			onStatus (r) {
				const { id, no, out_no, purchase_materials_count, status } = r;
				switch (status) {
					case 2:
						this.$message.error('审核中...');
					break;

					case 1:
					case 3:
						if (!purchase_materials_count) return this.$message.error('物料清单为0无法提交审核！');
						if (!this.$utils.create(this.$api.URI_APPROVALS)) return this.$message.error('暂无提交审核权限！');
						this.$confirm('No.' + no + '<br /><b style="color: #c7000a; font-size: 18px;">'+ purchase_materials_count + ' 款物料</b>', '确定要提交审核吗？', {
							dangerouslyUseHTMLString: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.post(this.$api.URI_APPROVALS, {common_model_id: id, common_model_type: 'App\\Models\\MaterialPurchase'});
							const { code, msg: message } = res.data;
							if (code != 0) return this.$message.error(message);
							this.$message({
								type: 'success',
								duration: 1000,
								message,
								onClose: () => {
									this.getSuppliers(this.params);
								}
							});
						});
					break;

					case 4:
						this.purchase = { id, no, out_no };
						this.create_dialog = true;
					break;

					case 5:
					case 6:
						this.getSheets({material_purchase_id: id, perPage: 100});
						this.purchase = { ...r };
						this.sheets_drawer = true;
					break;

					default:
						this.$message.error('未知状态操作！');
					break;
				}
			},
			importSupplies ({raw}) {
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				fileReader.onload = async ({target}) => {
					try {
						const { filter, thead } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						if (data.length <= 0) return this.$message.error("无需要导入记录！");
						let index = 0;
						let notify = this.$notify({
							title: '提示',
							dangerouslyUseHTMLString: true,
							message: '<p>正在准备导入采购物料...</p>',
							showClose: false,
							duration: 0
						});
						for (var i in data) {
							const { material_no, amount } = data[i];
							if (!material_no) {
								data[i].status = "提交物料编码不能为空";
								continue;
							}
							if (!amount) {
								data[i].status = "提供数量";
								continue;
							}
							index += 1;
							notify.message = '<p>正在导入 ' + index + ' / ' + data.length + '</p>';
							const res = await this.$http.post(this.$api.URI_MATERIALS_PURCHASES_SHEETS, {...data[i], material_purchase_id: this.purchase.id});
							const { code, msg } = res.data;
							data[i].status = msg;
							if (code != 0) continue;
						}
						if (index === data.length) {
							notify.message = '<p>导入完成！</p>';
							this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "采购物料导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
							this.getSheets({material_purchase_id: this.purchase.id,  perPage: 100});
							setTimeout(() => {
								notify.close();
							}, 3000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			downloadTemp () {
				ad_export_json_to_excel_multi_sheet([this.table_temp], '物料导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			}
		},
		data() {
			return {
				inventories: [],
				warehouses: [],
				suppliers: [],
				statuses: [],
				sheets: [],
				sheet: {},
				purchase: {},
				create_dialog: false,
				sheets_drawer: false,
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getInventories(this.params, true);
		}
	};
</script>